<mat-toolbar class="header not-top">
	<!-- [ngClass]="{ 'not-top': state === 'not-top' || partnerCode !== 'ps' }" -->
	<div
		class="menu-container"
		[ngClass]="{
			'menu-container': partnerCode === 'ps',
			'menu-container-affiliate': partnerCode !== 'ps'
		}"
	>
		<!-- Affiliates logo -->
		<a *ngIf="partnerCode !== 'ps'" href="/">
			<img
				src="https://storage.googleapis.com/ps-asset/affiliate/logo/{{
					partnerCode
				}}-logo.png"
				class="affiliate-logo"
				alt="affiliate-logo"
				[ngClass]="'header__' + partnerCode + '-affiliate-header'"
			/>
		</a>

		<!-- PS logo -->
		<a *ngIf="partnerCode === 'ps'" href="https://chubb-car.policystreet.app/">
			<!-- Mobile logo PS -->
			<img
				src="./assets/image/{{ partnerCode }}-logo.png"
				class="page-logo"
				alt="policy-street-logo"
			/>
			<!-- Desktop logo PS -->
			<img
				src="./assets/image/ps-logo-desktop.png"
				class="desktop-page-logo"
				alt=""
			/>
		</a>

		<span fxFlex></span>

		<span class="mobile-actions">
			<button
				mat-stroked-button
				[matMenuTriggerFor]="language"
				class="header-language mh-xs"
			>
				<span *ngIf="translate.currentLang === 'en'">{{
					"language.enShort" | translate
				}}</span>
				<span *ngIf="translate.currentLang !== 'en'">{{
					"language." + translate.currentLang | translate
				}}</span>
				<img src="assets/image/language2.png" alt="" />
				<!-- {{ "language." + translate.currentLang | translate }} -->
			</button>

			<mat-menu #language="matMenu">
				<button mat-menu-item (click)="switch('en')">
					<span>{{ "language.enShort" | translate }}</span>
				</button>
				<button mat-menu-item (click)="switch('bm')">
					<span>{{ "language.bm" | translate }}</span>
				</button>
			</mat-menu>

			<button
				*ngIf="partnerCode === 'ps'"
				mat-icon-button
				(click)="openMenu()"
				class="header__menu-btn"
			>
				<mat-icon>menu</mat-icon>
			</button>

			<!-- Affiliates menu button -->
			<button
				*ngIf="partnerCode !== 'ps'"
				mat-icon-button
				(click)="openMenu()"
				class="affiliates-header-menu"
			>
				<mat-icon>menu</mat-icon>
			</button>
		</span>

		<div class="header__desktop-btn">
			<button
				mat-stroked-button
				[matMenuTriggerFor]="language"
				class="header-language mh-xs"
			>
				<span *ngIf="translate.currentLang === 'en'">{{
					"language.enShort" | translate
				}}</span>
				<span *ngIf="translate.currentLang !== 'en'">{{
					"language." + translate.currentLang | translate
				}}</span>
				<img src="assets/image/language2.png" alt="" />
			</button>

			<button
				mat-raised-button
				(click)="guideDialog()"
				class="header__desktop-btn__guide mat-elevation-z0"
			>
				<img
					src="assets/icons/guides_icon_unfiltered.png"
					alt=""
					class="header__desktop-btn__icon-filter"
				/>
				{{ "floatingMenu.guide" | translate }}
			</button>

			<button
				mat-raised-button
				(click)="faqDialog()"
				class="header__desktop-btn__faq mat-elevation-z0"
				*ngIf="showFAQ"
			>
				<img
					src="assets/icons/faq_icon_unfiltered.png"
					alt=""
					class="header__desktop-btn__icon-filter"
				/>
				{{ "floatingMenu.faq" | translate }}
			</button>

			<button
				*ngIf="showWhatsappBtn"
				mat-raised-button
				(click)="whatsppRedirect()"
				class="header__desktop-btn__whatsapp mat-elevation-z0"
			>
				<img
					src="assets/icons/whatsapp_icon_unfiltered.png"
					alt=""
					class="header__whatsapp"
				/>
				<span>WhatsApp</span>
			</button>
		</div>
	</div>
</mat-toolbar>

<!-- Fixed whatsapp button in mobile view -->
<!-- <img
	(click)="whatsppRedirect()"
	class="whatsapp-button"
	src="assets/image/whatsapp-icon.png"
	alt=""
	*ngIf="showWhatsappBtn"
/> -->
