import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import * as moment from "moment";

@Component({
	selector: "app-submit-success-dialog",
	templateUrl: "./submit-success-dialog.component.html",
	styleUrls: ["./submit-success-dialog.component.css"],
})
export class SubmitSuccessDialogComponent {
	data: any;
	editQuotations: boolean = false;
	onOperation: boolean;
	responseTime: string = "5 - 20";
	channel;
	constructor(
		@Inject(MAT_DIALOG_DATA)
		data: {
			content?: string;
			boldContent?: string;
			editQuotations?: boolean;
		},
		gtmService: GoogleTagManagerService,
	) {
		this.data = data;
		this.editQuotations = data.editQuotations;
		gtmService.pushTag({
			event: "car_leads",
		});
		this.onOperation = this.isOnOperation();
		this.channel = this.data.customerInput?.preferredChannel;
	}

	private isOnOperation() {
		let today = moment();
		let day = today.day();
		if (day == 6 || day == 0) this.responseTime = "5 - 30";

		let before = moment().set({
			hour: 22,
			minute: 0,
			second: 0,
			millisecond: 0,
		});
		let after = moment().set({
			hour: 10,
			minute: 0,
			second: 0,
			millisecond: 0,
		});

		return today.isBetween(after, before);
	}
}
