import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import {
	ProductAddOnInterface,
	ProductInterface,
	QuotationInterface,
	MotorRequestInterface,
	VehicleListingInterface,
	PromoCodeInterface,
	ProductDocumentInterface,
	TRPaymentPayloadInterface,
} from "../interface/motor.interface";

@Injectable({
	providedIn: "root",
})
export class MotorDataDataService {
	private basePath: string = `${environment.url}/api/Motor/`;

	constructor(private http: HttpClient) {}

	getDetailById(id: string) {
		return this.http.get<MotorRequestInterface>(
			`${this.basePath}GetRequestInfoById/${id}`,
		);
	}

	getQuotationById(id: string) {
		return this.http.get<QuotationInterface[]>(
			`${this.basePath}GetQuotation/${id}`,
		);
	}

	getAccessList(id: string){
		return this.http.get<any>(
			`${this.basePath}GetMotorExcess/${id}`,
		);
	}

	getVehicleListing(limit: number, offset: number, text: string, make: string) {
		return this.http.post<VehicleListingInterface[]>(
			`${this.basePath}GetVehicleModelListing`,
			{
				limit,
				offset,
				text,
				make,
			},
		);
	}

	getAllProduct(id: string) {
		return this.http.get(`${this.basePath}GetAllProduct/${id}`);
	}

	getNonQuoteProduct(id: string) {
		return this.http.get<ProductInterface[]>(
			`${this.basePath}GetNonQuoteProduct/${id}`,
		);
	}

	getAddOn(id: string, quotationId: string) {
		return this.http.get<ProductAddOnInterface[]>(
			`${this.basePath}GetAddOn/${id}?quotationId=${quotationId}`,
		);
	}

	applyPromoCode(id: string, code: string, totalGrossPremium: number, motorQuotationId?: string) {
		return this.http.post<PromoCodeInterface>(
			`${this.basePath}PromoCode/${id}`,
			{
				code,
				totalGrossPremium,
				motorQuotationId
			},
		);
	}

	editRequest(id: string, val: any) {
		return this.http.post<void>(`${this.basePath}EditRequest/${id}`, val);
	}

	getProductDocumentById(id: string, language: string, insuranceType: string) {
		return this.http.get<ProductDocumentInterface[]>(
			`${this.basePath}GetProductDocument?id=${id}&language=${language}&insuranceType=${insuranceType}`,
		);
	}

	generatePayment(id: string, val: any) {
		return this.http.post<{ id: string }>(
			`${this.basePath}GeneratePayment/${id}`,
			val,
		);
	}

	generateTopupPayment(id: string, value: TRPaymentPayloadInterface) {
		return this.http.post<{ id: string }>(
			`${this.basePath}GenerateTopUpPayment/${id}`,
			value
		);
	}

	getPaymentById(id: string) {
		return this.http.get<{
			provider: "IPay88" | "Shopback" | "Razer" | "Payex";
			data: string;
		}>(`${this.basePath}GetPayment/${id}`);
	}

	checkPayment(refNo: string) {
		return this.http.get<{ status: boolean; data: any; amount: number }>(
			`${this.basePath}CheckPayment?refNo=${refNo}`,
		);
	}

	uploadPhoto(id: string, file: any) {
		return this.http.post<{ id: string }>(
			`${this.basePath}UploadPhoto/${id}`,
			file,
		);
	}

	getPolicyPhotos(id: string) {
		return this.http.get(`${this.basePath}GetPolicyPhotos/${id}`);
	}

	getPolicyTopUpDetail(id: string) {
		return this.http.get(`${this.basePath}GetPolicyTopUpDetail/${id}`);
	}

	editCarVariant(linkId: string, vehicleId: string) {
		return this.http.post<any>(`${this.basePath}EditCarVariant/${linkId}?vehicleId=${vehicleId}`, null);
	}

	getEPPRates() {
		return this.http.get(`${this.basePath}GetEppRates`);
	}
}
