import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-addon-card',
  templateUrl: './addon-card.component.html',
  styleUrls: ['./addon-card.component.scss'],
})
export class AddonCardComponent implements OnInit {
  @Input() img: string = '';
  @Input() title: string = '';
  @Input() label: boolean = false;
  @Input() code: string = '';
  @Input() includedAddOn: boolean = false;
  @Input() tooltip: string = '';
  @Input() desc: string = '';
  @Input() price: any = 0;

  @Input() selected: boolean = false;
  @Input() addOnSelected: boolean = false;

  @Output() toggleCheckbox = new EventEmitter();

  constructor(public translate: TranslateService) {}

  public get showRHBRibbon() {
    return this.code == 'MPA'
  }

  ngOnInit(): void {}

  toggleAddOn(e: any) {
    this.toggleCheckbox.emit(e);
  }
}
