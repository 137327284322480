<div>
	<div class="success-graphic">
		<img src="assets/image/submit-success-dialog.png" alt="" />
		<div>
			{{ "success.bestPrice" | translate }}
		</div>
	</div>
	<div class="success-content">
		<ng-container *ngIf="!editQuotations">
			<div>
				<!-- {{ data?.content | translate }}
				<span class="bold">{{ data?.boldContent }}</span>
				<span> {{ "dashboard.preferredChannelDuration" | translate }}</span> -->
				<div class="bold">
					{{ "dashboard.expectations" | translate }}
					{{
						channel === "Email"
							? data.customerInput.email
							: data.customerInput.mobileNo
					}}
				</div>

				<ul>
					<li class="mt-xs">
						{{ "dashboard.workingHours" | translate
						}}<span class="bold">{{
							"dashboard.workingHours2" | translate
						}}</span>
					</li>
					<li class="mt-xs">
						{{ "dashboard.outsideWorkingHours" | translate }}
					</li>
					<li class="mt-xs">
						{{ "dashboard.urgent1" | translate }}
						<span class="bold">{{ "dashboard.urgent2" | translate }}</span>
						<span>{{ "dashboard.urgent3" | translate }}</span>
					</li>
				</ul>
			</div>
		</ng-container>
		<ng-container *ngIf="editQuotations">
			<span *ngIf="onOperation">
				{{
					"success.quotationsProcessed"
						| translate : { responseTime: responseTime }
				}}
				<!-- {{ "success.dropEmail" | translate }}
				<a href="mailto:info@policystreet.com">info@policystreet.com</a>. -->
			</span>
			<span *ngIf="!onOperation">
				{{ "success.getBack" | translate }}
				{{ "success.dropEmail" | translate }}
				<a href="mailto:info@policystreet.com">info@policystreet.com</a>.
			</span>
		</ng-container>
		<button mat-flat-button mat-primary mat-dialog-close>
			{{ "success.close" | translate }}
		</button>
	</div>
</div>
