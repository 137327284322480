import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from "@angular/forms";
import {map, Subject, tap} from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-range-slider",
  templateUrl: "./range-slider.component.html",
  styleUrls: ["./range-slider.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RangeSliderComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: RangeSliderComponent,
      multi: true,
    },
  ],
})
export class RangeSliderComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator
{
  @Input() max = 0;
  @Input() maxError = "";
  @Input() min = 0;
  @Input() minError = "";
  @Input() step = 100;
  @Input() variantSmall = false;

  formControl = this.fb.control(0);
  private destroy$ = new Subject<void>();

  constructor(private fb: FormBuilder) {}

  onTouched = () => {};

  ngOnInit(): void {
    this.initValidators();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onAdd(): void {
    this.onTouched();
    this.formControl.patchValue(+this.formControl.value + this.step);
  }

  onRemove(): void {
    this.onTouched();
    if (this.formControl.value <= 0) {
      return;
    }
    this.formControl.patchValue(+this.formControl.value - this.step);
  }

  writeValue(value: number): void {
    this.formControl.patchValue(value);
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  registerOnChange(onChange: () => void): void {
    this.formControl.valueChanges
      .pipe(takeUntil(this.destroy$), map((v) => {
          const parsedValue = `${v}`.replace(/^0+(?=\d)/, '') || 0;
          this.formControl.patchValue(parsedValue, {emitEvent: false});
          return parsedValue;
        })
      )
      .subscribe(onChange);
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled
      ? this.formControl.disable()
      : this.formControl.enable();
  }

  validate(): ValidationErrors | null {
    return this.formControl.valid ? null : { invalidNumber: true };
  }

  private initValidators(): void {
    this.formControl.setValidators([
      Validators.min(this.min),
      Validators.max(this.max),
    ]);
    this.formControl.updateValueAndValidity();
  }
}
