import { MotorQuotationInterface } from "./../../interface/motor-quotation.interface";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({
	providedIn: "root",
})
export class MotorQuotationDataService {
	private basePath: string = `${environment.url}/api/Microsite/`;

	constructor(private http: HttpClient) {}

	submit(val: MotorQuotationInterface) {
		return this.http.post<{
			isSuccess: boolean;
			data: string;
		}>(`${this.basePath}Submit`, val);
	}
}
