export const PAYMENT_OPTION_FP = "FP";
export const PAYMENT_OPTION_RAZER = "Razer";
export const PAYMENT_OPTION_SHOPBACK = "Shopback";
export const PAYMENT_OPTION_PAYEX = "Payex";
export const PAYMENT_OPTION_EPP = "EPP";
export const PAYMENT_OPTION_EPP_3_6_12_MONTHS = "EPP3612";
export const PAYMENT_OPTION_EPP_6_12_MONTHS = "EPP612";

export const PAYMENT_OPTION_VISA = "credit";
export const PAYMENT_OPTION_FPX = "fpx";
export const PAYMENT_OPTION_EWALLET = "EWALLET";

export const PAYMENT_FULL_PRICE = [
    {
        name: 'Visa / Mastercard',
        value: 'credit'
    },
    {
        name: 'Online Banking (FPX',
        value: 'fpx'
    },
    {
        name: 'e-Wallet',
        value: 'e-walet'
    }
]

export const PAYMENT_EWALLET_TYPE = [
    {
        name: "Touch 'nGo eWallet",
        value: 'TNG-EWALLET',
        img: '/assets/icons/payments/e-wallet/tngo-logo.png'
    },
    {
        name: "DuitNow QR",
        value: 'RPP_DuitNowQR',
        img: '/assets/icons/payments/e-wallet/duitnow-logo.png'
    },
    {
        name: "Grabpay",
        value: 'GrabPay',
        img: '/assets/icons/payments/e-wallet/grabpay-logo.png'
    },
    {
        name: "Shopee Pay",
        value: 'ShopeePay',
        img: '/assets/icons/payments/e-wallet/shopeepay-logo.png'
    },
    {
        name: "Boost",
        value: 'BOOST',
        img: '/assets/icons/payments/e-wallet/boost-logo.png'
    },
    {
        name: "MAE by Maybank2u",
        value: 'MB2U_QRPay-Push',
        img: '/assets/icons/payments/e-wallet/mae-logo.png'
    },
    {
        name: "Alipay",
        value: 'alipay',
        img: '/assets/icons/payments/e-wallet/alipay-logo.png'
    },
]

export const PAYMENT_EPP_TYPE = [
    {
        name: "AmBank (M) Berhad",
        value: 'creditU',
        img: '/assets/icons/payments/banks/ambank-logo.png'
    },
    {
        name: "Hong Leong Bank Berhad",
        value: 'creditAE',
        img: '/assets/icons/payments/banks/hongleong-logo.png'
    },
    {
        name: "Affin Bank Berhad",
        value: 'crediAB',
        img: '/assets/icons/payments/banks/affin-logo.png'
    },
    {
        name: "RHB Bank Berhad",
        value: 'creditAH',
        img: '/assets/icons/payments/banks/rhb-logo.png'
    },
    {
        name: "CIMB Bank Berhad",
        value: 'creditL',
        img: '/assets/icons/payments/banks/cimb-logo.png'
    },
]