<mat-card class="container" [ngClass]="{'bundle-selected': selected, 'addon-selected' : addOnSelected, 'addon-included': includedAddOn }">
  <img [src]="'/assets/icons/included_addon_' + translate.currentLang + '.png'" alt="" *ngIf="includedAddOn" class="ribbon">
  <div class="addon-info">
      <div class="addon-main">
        <div>
          <img [src]="img" alt="" class="addon-img" />
        </div>
        <div class="content">
          <div *ngIf="showRHBRibbon" class="content__header">{{ "addOn.exclusiveRHB" | translate }}</div>
          <div class="content__title">{{ title }}</div>
          <div class="content__label" *ngIf="label">
            {{ 'plans.onlyOn' | translate }} <span class="bold">PolicyStreet</span>
          </div>
          <div class="content__desc">{{ desc }}</div>
          <ng-container *ngIf="price">
            <div class="content__price" *ngIf="selected">RM 0.00 {{ 'plans.included' | translate }}</div>
            <div class="content__price" *ngIf="!selected">RM {{ price | number : "1.2-2" }}</div>
          </ng-container>
          <ng-content select="div[slot=normalWidth]"></ng-content>
        </div>
      </div>
      <div class="flex">
        <button
          mat-icon-button
          type="button"
          [matTooltip]="tooltip"
          matTooltipPosition="left"
          *ngIf="tooltip && !includedAddOn"
          #tooltips="matTooltip"
          (click)="tooltips.toggle()"
        >
          <img src="assets/icons/info-outline.png" alt="">
        </button>
        <mat-checkbox 
          [checked]="addOnSelected || selected"
          [disabled]="selected"
          (change)="toggleAddOn($event)"
          *ngIf="!includedAddOn"
        ></mat-checkbox>
      </div>
    </div>
    <ng-content select="div[slot=fullWidth]"></ng-content>
</mat-card>
