// General Import
import {
	Component,
	HostListener,
	Input,
	OnInit,
	ViewChild,
} from "@angular/core";
// Angular Material
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
// Service
import { TranslateService } from "@ngx-translate/core";
import { PartnerService } from "src/app/share/service/partner.service";
// Component
import { MenuMobileComponent } from "../menu/menu-mobile/menu-mobile.component";
import { GuidesDialogComponent } from "src/app/dashboard/guides-dialog/guides-dialog.component";
import { FaqDialogComponent } from "src/app/dashboard/faq-dialog/faq-dialog.component";
import { FaqTakafulDialogComponent } from "src/app/dashboard/faq-dialog/faq-takaful-dialog/faq-takaful-dialog.component";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
})
export class AppHeaderComponent implements OnInit {
	partnerCode: string = "ps";
	showWhatsappBtn: Boolean = true;
	hideWhatsappBtnList: string[] = ["wapcar", "carsome", "carlist"];
	showFAQ: Boolean = false;
	showFAQList: string[] = ["ps", "cbp"];

	@Input() state = ""; // decorate the property with @Input()
	@ViewChild("menuTrigger") menuTrigger: MatMenuTrigger;

	@HostListener("window:scroll", []) onWindowScroll() {
		// do some stuff here when the window is scrolled
		const verticalOffset =
			window.pageYOffset ||
			document.documentElement.scrollTop ||
			document.body.scrollTop ||
			0;
	}

	constructor(
		public translate: TranslateService,
		private dialog: MatDialog,
		private partnerService: PartnerService,
	) {}

	ngOnInit() {
		this.getParams();
	}

	private getParams() {
		this.partnerCode = this.partnerService.getPartnerCode();
		if (this.hideWhatsappBtnList.includes(this.partnerCode)) {
			this.showWhatsappBtn = false;
		}

		if (this.partnerCode == "ctos") {
			let partner = this.partnerService.partnerList.find(
				(x) => x.code == this.partnerCode,
			);
			this.partnerService.appendGtmScript(partner.gtmID);
			this.partnerService.appendHeaderScript(`<!-- BEGIN PLERDY CODE -->
      <script type="text/javascript" defer data-plerdy_code='1'>
      var _protocol="https:"==document.location.protocol?" https://":" http://";
      _site_hash_code = "93e87d8451fbf8a79fffee4e4da41f1d",_suid=25861, plerdyScript=document.createElement("script");
      plerdyScript.setAttribute("defer",""),plerdyScript.dataset.plerdymainscript="plerdymainscript",
      plerdyScript.src=https://a.plerdy.com/public/js/click/main.js?v=+Math.random();
      var plerdymainscript=document.querySelector("[data-plerdymainscript='plerdymainscript']");
      plerdymainscript&&plerdymainscript.parentNode.removeChild(plerdymainscript);
      try{document.head.appendChild(plerdyScript)}catch(t){console.log(t,"unable add script tag")}
      </script>
      <!-- END PLERDY CODE -->`);
		}
		!this.partnerCode ? (this.partnerCode = "ps") : null;
		if (this.showFAQList.includes(this.partnerCode)) {
			this.showFAQ = true;
		}
	}

	openMenu() {
		this.dialog.open(MenuMobileComponent, {
			panelClass: "menu-dialog",
			backdropClass: "menu-blackdrop",
			disableClose: true,
		});
	}
	switch(lang: string) {
		this.translate.use(lang);
	}

	guideDialog() {
		this.dialog.open(GuidesDialogComponent, {
			panelClass: "guide-dialog",
		});
	}

	faqDialog() {
		if (this.partnerService.getPartnerAttribute(this.partnerCode, "takaful")) {
			this.dialog.open(FaqTakafulDialogComponent, {
				panelClass: "faq-dialog",
			});
		} else {
			this.dialog.open(FaqDialogComponent, {
				panelClass: "faq-dialog",
			});
		}
	}

	whatsppRedirect() {
		const whatsappNumber = 60182822320;
		const entityName = "Policystreet";
		let whatsappContent = "";
		let lang = this.translate.currentLang == "en" ? "en" : "ms";
		whatsappContent =
			lang == "ms"
				? `Hai ${entityName}, saya ingin tahu dengan lebih lanjut mengenai cukai jalan dan insurans kenderaan`
				: `Hi ${entityName}, I would like to know more about road tax and car insurance`;

		let url = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(
			whatsappContent,
		)}&source=&data=&app_absent=&lang=${lang}`;

		window.open(url, "_blank");
	}
}
