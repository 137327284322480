<mat-expansion-panel class="summary-card-container" [expanded]="false">
  <mat-expansion-panel-header>
    <div class="header">
      <div class="header__title">{{ title }}</div>
      <button
        mat-stroked-button
        class="header__edit-btn"
        *ngIf="editable"
        (click)="edit($event)"
      >
      {{ 'common.edit' | translate }}
      </button>
    </div>
  </mat-expansion-panel-header>
  <div class="divider"></div>
  <div class="content-container">
    <ng-content></ng-content>
  </div>
</mat-expansion-panel>
