import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
	providedIn: "root",
})
export class MasterDataDataService {
	private basePath: string = `${environment.url}/api/Microsite/`;

	constructor(private http: HttpClient) {}

	getCountrySelection() {
		return this.http.get<string[]>(`${this.basePath}GetCountrySelection`);
	}

	selectAllPostcodes(){
		return this.http.get<string[]>(`${this.basePath}SelectAllPostcodes`)
	}
}
