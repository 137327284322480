import { PartnerInfoListInterface } from "../interface/partner.interface";

export const PARTNER_INFO: PartnerInfoListInterface = {
	carsome: {
		isDisplay: true,
		partner: "carsome",
		company: "Carsome",
		email: { value: "capital.insurance@carsome.my", isDisplay: true },
		mobile: { value: "", isDisplay: false },
		address: {
			value:
				"Level 9, KYM Tower, No. 8, Jalan PJU 7/6, Mutiara Damansara, 47800 Petaling Jaya, Selangor",
			isDisplay: false,
		},
		copyright: { value: "Carsome Sdn Bhd (201401025864)", isDisplay: false },
	},
	wapcar: {
		isDisplay: false,
		partner: "wapcar",
		company: "Wapcar",
		email: { value: "capital.insurance@carsome.my", isDisplay: true },
		mobile: { value: "+6019 388 2993", isDisplay: false },
		address: {
			value:
				"Level 9, KYM Tower, No. 8, Jalan PJU 7/6, Mutiara Damansara, 47800 Petaling Jaya, Selangor",
			isDisplay: false,
		},
		copyright: {
			value: "Wapcar Autofun Sdn Bhd (202101026733)",
			isDisplay: false,
		},
	},
	carlist: {
		isDisplay: false,
		partner: "carlist",
		company: "Carlist",
		email: { value: "enquiries@carlist.my", isDisplay: true },
		mobile: { value: "1300 30 4227", isDisplay: false },
		address: {
			value:
				"Suite 18.01, Level 18, Centrepoint North Tower, Mid Valley City, Lingkaran Syed Putra, 59200 K.L, W.P",
			isDisplay: false,
		},
		copyright: { value: "iCar Asia Sdn Bhd (200701040130)", isDisplay: false },
	},
};
